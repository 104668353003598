import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import logo from '../../assets/images/logo/dominos-logo.png'
import loginBackground from '../../assets/images/background-images/login-background.png'
import classNames from 'classnames'
import InputField from 'ui-components/TextFeild'
import Button from 'ui-components/Button'
import { set_localize_content } from 'utils/helper'
import {
    forgot_password_translation,
    reset_password_translation,
} from 'translation'
import apiServices from 'services/RequestHandler'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export default function ResetPassword(props: any) {
    const [state, setState] = React.useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            password: '',
            confirmpassword: '',
            showPassword: false,
            showConfirmPassword: false,
        }
    )

    const { classes } = props
    const navigate = useNavigate()
    const { id } = useParams()

    const reset_password = async () => {
        if (!password) {
            toast.error('Please enter password')
        } else if (!confirmpassword) {
            toast.error('Please enter confirm password')
        } else {
            let data = {
                password: password,
                password_confirm: confirmpassword,
            }

            let response = await apiServices.postFromApi(
                `/users/admin-reset-password/${id}`,
                data,
                'users'
            )

            if (response?.error?.error) {
                toast.error(response?.error?.error)
            } else {
                toast.success('Password Reset Successfully.')
                navigate('/login')
            }
        }
    }

    const handleClickShowPassword = () => {
        setState({ showPassword: !state.showPassword })
    }

    const handleClickShowConfirmPassword = () => {
        setState({ showConfirmPassword: !state.showConfirmPassword })
    }

    const { password, confirmpassword, showPassword, showConfirmPassword } =
        state

    return (
        <Grid
            container
            style={{
                backgroundImage: `url(${loginBackground})`,
                height: '100vh',
                maxHeight: '100vh',
            }}
            className={classNames(classes.align_center, classes.justify_center)}
        >
            <Grid
                item
                style={{ height: '50vh', width: '50vh' }}
                className={classNames(
                    classes.direction_column,
                    classes.align_center,
                    classes.justify_center
                )}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        className={classNames(
                            classes.align_center,
                            classes.justify_center,
                            classes.direction_column
                        )}
                    >
                        <Grid style={{ marginBottom: '15px' }}>
                            <img src={logo} width={300} alt={"Domino's Logo"} />
                        </Grid>

                        <Grid style={{ marginBottom: '15px' }}>
                            <Typography
                                variant="h4"
                                className={classNames(
                                    classes?.typography_login_heading
                                )}
                            >
                                {set_localize_content(
                                    reset_password_translation?.welcome,
                                    'en'
                                )}
                            </Typography>{' '}
                        </Grid>

                        <Grid style={{ marginBottom: 10 }}>
                            <InputField
                                type={!showPassword ? 'password' : 'text'}
                                fullWidth
                                value={password}
                                size={'small'}
                                variant={'outlined'}
                                white_space_enable={false}
                                placeholder={set_localize_content(
                                    reset_password_translation?.password_placeholder,
                                    'en'
                                )}
                                InputProps={{
                                    className: classNames(
                                        classes?.input_feild,
                                        'Open Sans'
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ marginRight: 10 }}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    className: classNames(
                                        classes?.input_feild,
                                        'Open Sans'
                                    ),
                                }}
                                onChange={(e: any) => {
                                    const { value } = e.target
                                    setState({ password: value })
                                }}
                                classes={classes}
                                required={true}
                                input_label={set_localize_content(
                                    reset_password_translation?.password,
                                    'en'
                                )}
                                white_input_label={true}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        reset_password()
                                    }
                                }}
                            />
                            <br />

                            <InputField
                                type={
                                    !showConfirmPassword ? 'password' : 'text'
                                }
                                fullWidth
                                value={confirmpassword}
                                size={'small'}
                                variant={'outlined'}
                                white_space_enable={false}
                                placeholder={set_localize_content(
                                    reset_password_translation?.confirm_password_placeholder,
                                    'en'
                                )}
                                InputProps={{
                                    className: classNames(
                                        classes?.input_feild,
                                        'Open Sans'
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ marginRight: 10 }}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowConfirmPassword
                                                }
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    className: classNames(
                                        classes?.input_feild,
                                        'Open Sans'
                                    ),
                                }}
                                onChange={(e: any) => {
                                    const { value } = e.target
                                    setState({ confirmpassword: value })
                                }}
                                classes={classes}
                                required={true}
                                input_label={set_localize_content(
                                    reset_password_translation?.confirm_password,
                                    'en'
                                )}
                                white_input_label={true}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        reset_password()
                                    }
                                }}
                            />
                        </Grid>

                        <br />

                        <Grid>
                            <Button
                                size={'small'}
                                value={set_localize_content(
                                    forgot_password_translation?.send_request,
                                    'en'
                                )}
                                onClick={reset_password}
                                variant={'outlined'}
                                classesNames={classNames(
                                    classes?.contained_button,
                                    classes?.button_width
                                )}
                            />
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
