import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/* MATERIAL UI */
import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    Tooltip,
    TooltipProps,
    Typography,
    tooltipClasses,
    useMediaQuery,
    useTheme,
} from '@mui/material'

/* MATERIAL ICONS*/
import {
    AssistantDirectionOutlined,
    ExpandLess,
    ExpandMore,
} from '@mui/icons-material'

/* PROJECT IMPORTS */
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getDataFromLocalStorage } from 'utils/localStore.utils'
import NavItem from '../NavItem'

const NavCollapse = ({ menu, level }: any) => {
    const theme = useTheme()
    const { pathname } = useLocation()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
    const leftDrawerOpened = useSelector(
        (state: RootState) => state.drawer.opened
    )

    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#d2112b',
        },
    }))

    const handleClick = () => {
        setOpen(!open)
        setSelected(!selected ? menu?.id : null)
    }

    const checkOpenForParent = (child: any, id: any) => {
        child.forEach((item: any) => {
            if (item?.url === pathname) {
                setOpen(true)
                setSelected(id)
            }
        })
    }

    useEffect(() => {
        setOpen(false)
        setSelected(null)
        if (menu?.children?.length > 0) {
            menu?.children?.forEach((item: any) => {
                if (item?.children?.length) {
                    checkOpenForParent(item?.children, menu?.id)
                }
                const currentIndex = pathname
                    .toString()
                    .split('/')
                    .findIndex((id: any) => id === item?.id)

                if (currentIndex > -1) {
                    setSelected(menu?.id)
                    setOpen(true)
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu?.children])

    const can_perform_action = (item: any) => {
        try {
            let user = getDataFromLocalStorage('user')
            let indexz = user?.permissions?.findIndex((itemx: any) => {
                return Number(itemx?.module_id) === Number(item?.module_id)
            })
            if (
                (item?.type === 'item' && user?.permissions?.[indexz]?.read) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.write) ||
                (item?.type === 'item' &&
                    user?.permissions?.[indexz]?.update) ||
                (item?.type === 'item' &&
                    user?.permissions?.[indexz]?.delete) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.print) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.download)
            ) {
                return true
            } else if (
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.read) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.write) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.update) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.delete) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.print) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.download)
            ) {
                return true
            }
        } catch (error) {
            console.log(error)
        }
    }

    // eslint-disable-next-line
    const menus = menu?.children?.map((item: any) => {
        if (can_perform_action(item)) {
            switch (item?.type) {
                case 'collapse':
                    return (
                        <NavCollapse
                            key={item?.id}
                            menu={item}
                            level={level + 1}
                        />
                    )
                case 'item':
                    return (
                        <NavItem key={item.id} item={item} level={level + 1} />
                    )
                default:
                    return (
                        <Typography
                            key={item?.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Menu Items Error
                        </Typography>
                    )
            }
        }
    })

    const getDrawerIcons = (type: any) => {
        if (type === 'settings') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/settings.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="settings icon"
                />
            )
        }
        if (type === 'categories') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/categories.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="categories icon"
                />
            )
        }
        if (type === 'pizza') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/pizza.png')}
                    width={20}
                    height={20}
                    style={{
                        marginLeft: leftDrawerOpened ? 11 : 0,
                        marginRight: 10,
                    }}
                    alt="pizza icon"
                />
            )
        }
        if (type === 'products') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/products.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="products icon"
                />
            )
        }
        if (type === 'orders') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/orders.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="products icon"
                />
            )
        }
        if (type === 'management') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/management.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="products icon"
                />
            )
        }
        if (type === 'delivery') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/delivery.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="delivery icon"
                />
            )
        }
        if (type === 'careers') {
            return (
                <img
                    src={require('../../../../assets/DrawerPNGICON/active/careers.png')}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                    alt="delivery icon"
                />
            )
        } else {
            return <AssistantDirectionOutlined style={{ marginRight: 10 }} />
        }
    }

    return (
        <>
            <BootstrapTooltip
                title={!leftDrawerOpened && menu?.title}
                placement="right"
            >
                <ListItemButton
                    className="list-item"
                    sx={{
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor:
                            level > 1 ? 'transparent !important' : 'inherit',
                        py: 0.8,
                        pl: 1,
                        pr: 0,
                        textTransform: 'uppercase',
                        borderRadius: 1,
                    }}
                    onClick={handleClick}
                    disabled={menu?.disabled}
                >
                    <ListItemIcon
                        sx={{
                            my: 'auto',
                            minWidth: !menu?.icon ? 18 : 36,
                            color: '#d2112b',
                            mx: 'auto',
                        }}
                    >
                        {getDrawerIcons(menu?.id)}
                    </ListItemIcon>

                    {leftDrawerOpened ? (
                        <p style={{ marginBottom: 0, flex: 1 }}>
                            {menu?.title}
                        </p>
                    ) : null}

                    {matchesSM && !leftDrawerOpened ? (
                        <p
                            style={{
                                marginBottom: 0,
                                flex: 1,
                                paddingLeft: 10,
                            }}
                        >
                            {menu?.title}
                        </p>
                    ) : null}

                    {leftDrawerOpened ? (
                        open ? (
                            <ExpandLess
                                style={{
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                }}
                            />
                        ) : (
                            <ExpandMore
                                style={{
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                }}
                            />
                        )
                    ) : null}
                </ListItemButton>
            </BootstrapTooltip>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menus}
                </List>
            </Collapse>
        </>
    )
}

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
}

export default NavCollapse
