import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import apiServices from "services/RequestHandler"

export interface ItemsState {
  all_couriers: any
  all_couriers_data: any
  single_courier: any
  create_courier: any
  update_courier: any
  get_ongoing_orders: any
  all_couriers_paginate: any
  all_export_data: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: ItemsState = {
  all_couriers: [],
  all_couriers_data: [],
  single_courier: {},
  create_courier: {},
  update_courier: {},
  get_ongoing_orders: [],
  all_couriers_paginate: [],
  all_export_data: [],
  activity: false,
}

export const all_courier_pagination = createAsyncThunk(
  "/couriers/pagination",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "couriers"
      )
      return {
        ...response,
      }
    } catch (err) {
      console.log(err)
    }
  }
)
export const all_courier_request = createAsyncThunk(
  "/couriers",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, data.body)
      return {
        ...response,
      }
    } catch (err) {
      console.log(err)
    }
  }
)
export const all_courier_export_data = createAsyncThunk(
  "/couriers/export",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(data.end_point, data.body, "couriers-export")
      return {
        ...response,
      }
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_singal_courier_request = createAsyncThunk(
  `/courier/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "courier")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_courier_request = createAsyncThunk(
  "/courier/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "courier/add"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_courier_request = createAsyncThunk(
  "courier/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "courier"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_ongoing_order = createAsyncThunk(
  "/couriers/courier-orders",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "couriers/courier-orders"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const delivery_slicer = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    handle_clear_form: (state: ItemsState, action: any) => {
      state.single_courier = {}
      state.create_courier = {}
      state.update_courier = {}
      state.activity = false
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(all_courier_pagination.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(all_courier_pagination.fulfilled, (state, { payload }) => {
        try {
          state.all_couriers_data = payload?.data
          state.all_couriers = payload?.data
          state.all_couriers_paginate = payload
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })
    
      .addCase(all_courier_export_data.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(all_courier_export_data.fulfilled, (state, { payload }) => {
        try {
            console.log(state.all_couriers_paginate, 'all_couriers_paginate');
            
          state.all_export_data = payload?.data
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(all_courier_request.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(all_courier_request.fulfilled, (state, { payload }) => {
        try {
          state.all_couriers_data = payload?.data
          state.all_couriers = payload?.data
          state.all_couriers_paginate = payload
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })

      .addCase(create_courier_request.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(create_courier_request.fulfilled, (state, { payload }) => {
        try {
          state.create_courier = payload
          state.activity = false
        } catch (error) {
          state.activity = false
          console.log(error)
        }
      })

      .addCase(create_courier_request.rejected, (state, { payload }) => {
        try {
          state.activity = false
        } catch (error) {
          state.activity = false
          console.log(error)
        }
      })

      .addCase(
        update_courier_request.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )

      .addCase(
        update_courier_request.fulfilled,
        (state: any, { payload }: any) => {
          try {
            state.update_courier = payload
            state.activity = false
          } catch (error) {
            state.activity = false
            console.log(error)
          }
        }
      )

      .addCase(get_singal_courier_request.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(get_singal_courier_request.fulfilled, (state, { payload }) => {
        try {
          state.single_courier = {
            ...payload?.data,
          } as any
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })

      .addCase(get_ongoing_order.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(get_ongoing_order.fulfilled, (state, { payload }) => {
        try {
          state.get_ongoing_orders = payload
          state.activity = false
        } catch (error) {
          state.activity = false
          console.log(error)
        }
      })
  },
})

export const { handle_clear_form } = delivery_slicer.actions

export default delivery_slicer.reducer
