import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiServices from 'services/RequestHandler'

export interface SmsManagementState {
    get_all_sms_managment_data: any
    update_sms_management_data: any
    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: SmsManagementState = {
    get_all_sms_managment_data: [],
    update_sms_management_data: {},
    activity: false,
}

export const get_sms_management_data = createAsyncThunk(
    '/sms-management/',
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'sms-management'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_information = createAsyncThunk(
    '/sms-management/id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'sms-management'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const sms_management_slicer = createSlice({
    name: 'smsManagement',
    initialState,
    reducers: {
        handle_clear_form: (state: SmsManagementState, action: any) => {
            state.update_sms_management_data = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_sms_management_data.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(
                get_sms_management_data.fulfilled,
                (state, { payload }) => {
                    try {
                        state.get_all_sms_managment_data = payload?.data
                        state.activity = false
                    } catch (error) {
                        console.log(error)
                    }
                }
            )

            .addCase(update_information.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_information.fulfilled, (state, { payload }) => {
                try {
                    state.update_sms_management_data = payload
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })
    },
})

export const { handle_clear_form } = sms_management_slicer.actions

export default sms_management_slicer.reducer
