import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import apiServices from "services/RequestHandler"
import { dateFormat, timeFormatWithSec, utc_time_zone } from "./constants"

export interface sizezInterface {
  all_sizes: any
  all_sizes_data: any
  single_size: any
  create_size: any
  update_size: any
  delete_size: any
  activity: any
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: sizezInterface = {
  all_sizes: [],
  all_sizes_data: [],
  single_size: {},
  create_size: [],
  update_size: [],
  delete_size: [],
  activity: false,
}

export const all_sizes = createAsyncThunk(
  "all/sizes",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sizes")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_size = createAsyncThunk(
  "size/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sizes")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_size = createAsyncThunk(
  "create/size",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "size"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_size = createAsyncThunk(
  "update/size",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "size"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const sizesSlice = createSlice({
  name: "sizes",
  initialState,
  reducers: {
    handle_clear_form: (state: sizezInterface, action: any) => {
      state.all_sizes = {}
      state.single_size = {}
      state.create_size = {}
      state.update_size = {}
      state.delete_size = {}
      state.activity = false
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(all_sizes.pending, (state: any) => {
        state.activity = true
      })

      .addCase(all_sizes.fulfilled, (state: any, { payload }: any) => {
        try {
          let sizes = [] as any
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index]

            sizes.push([
              item?.size_code || "--",
              item?.size_decription.en || "--",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#d2112b",
              },
              {
                name: item?.created_at
                  ? moment(item?.created_at)
                      .utcOffset(utc_time_zone)
                      .format(`${dateFormat} ${timeFormatWithSec}`)
                  : "--",
              },
              { name: item?.added_by || "Admin" },
              {
                name:
                  item?.updated_at !== item?.created_at
                    ? moment(item?.updated_at)
                        .utcOffset(utc_time_zone)
                        .format(`${dateFormat} ${timeFormatWithSec}`)
                    : "--",
              },
              item?.modified_by
                ? { name: item?.modified_by || "--" }
                : {
                    name: item?.modified_by || "--",
                    alignment: "center",
                  },
            ])
          }

          state.all_sizes = sizes
          state.all_sizes_data = payload?.data
          state.activity = false
        } catch (err) {}
      })

      .addCase(get_single_size.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(get_single_size.fulfilled, (state: any, { payload }: any) => {
        try {
          state.single_size = payload.data as any
          state.activity = false
        } catch (error) {
          console.log(error)
        }
      })

      .addCase(create_size.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_size.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_size = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(update_size.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_size.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_size = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
  },
})

export const { handle_clear_form } = sizesSlice.actions

export default sizesSlice.reducer
