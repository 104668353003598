import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiServices from 'services/RequestHandler'

export interface ItemsState {
    /* CATEGORY */

    all_banner: any
    all_banner_data: any
    create_banner: any
    update_banner: any
    get_banner_by_id: any

    banner_image: any
    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    /* CATEGORY */
    all_banner: [],
    all_banner_data: [],
    create_banner: {},
    update_banner: {},
    get_banner_by_id: {},
    banner_image: '',
    activity: false,
}

/* banner */
export const all_banner = createAsyncThunk('/banner', async (data: any) => {
    try {
        let response = await apiServices.getFromApi(
            data.end_point,
            data.permission_name
        )
        return {
            ...response,
        }
    } catch (err) {
        console.log(err)
    }
})

export const get_banner = createAsyncThunk(
    `/banner/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'banner'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_banner = createAsyncThunk(
    '/banner/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'banner/add'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_banner = createAsyncThunk(
    '/banner/id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'banner'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

// upload banner images
export const upload_banner_media = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'bannerimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const banner_slicer = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.get_banner_by_id = {}
            state.create_banner = {}
            state.update_banner = {}

            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            /* banner */

            .addCase(all_banner.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_banner.fulfilled, (state, { payload }) => {
                // let all_category = [] as any

                state.all_banner = all_banner
                state.all_banner_data = payload?.data
                state.activity = false
            })

            .addCase(create_banner.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_banner.fulfilled, (state, { payload }) => {
                try {
                    state.create_banner = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(create_banner.rejected, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(update_banner.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_banner.fulfilled, (state, { payload }) => {
                try {
                    state.update_banner = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(get_banner.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_banner.fulfilled, (state, { payload }) => {
                try {
                    state.get_banner_by_id = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            // product image
            .addCase(
                upload_banner_media.pending,
                (state: any, { payload }: any) => {
                    state.activity = true
                }
            )
            .addCase(
                upload_banner_media.fulfilled,
                (state: any, { payload }: any) => {
                    state.banner_image = payload?.data
                    state.activity = false
                }
            )
    },
})

export const { handle_clear_form } = banner_slicer.actions

export default banner_slicer.reducer
