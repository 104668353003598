import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import { toast } from "react-toastify"
import apiServices from "services/RequestHandler"
import { dateFormat, timeFormatWithSec } from "./constants"

export interface productsInterface {
  all_products_data: any

  all_bread: any
  all_bread_data: any
  single_bread: any
  create_bread: any
  update_bread: any

  //chicken
  all_chicken: any
  all_chicken_data: any
  single_chicken: any
  create_chicken: any
  update_chicken: any

  //sandwich
  all_sandwich: any
  all_sandwich_data: any
  single_sandwich: any
  create_sandwich: any
  update_sandwich: any

  //wrap
  all_wrap: any
  all_wrap_data: any
  single_wrap: any
  create_wrap: any
  update_wrap: any

  //load_potato
  all_load_potato: any
  all_load_potato_data: any
  single_load_potato: any
  create_load_potato: any
  update_load_potato: any

  //dessert
  all_dessert: any
  all_dessert_data: any
  single_dessert: any
  create_dessert: any
  update_dessert: any

  //drink
  all_drink: any
  all_drink_data: any
  single_drink: any
  create_drink: any
  update_drink: any

  //souce
  all_souce: any
  all_souce_data: any
  single_souce: any
  create_souce: any
  update_souce: any

  //salad
  all_salad: any
  all_salad_data: any
  single_salad: any
  create_salad: any
  update_salad: any

  //topping
  all_topping: any
  all_topping_data: any
  single_topping: any
  create_topping: any
  update_topping: any

  //topping_price
  all_topping_price: any
  all_topping_price_data: any
  single_topping_price: any
  create_topping_price: any
  update_topping_price: any

  //pizza
  all_pizza: any
  all_pizza_data: any
  single_pizza: any
  create_pizza: any
  update_pizza: any

  //dough
  all_dough: any
  all_dough_data: any
  single_dough: any
  create_dough: any
  update_dough: any

  all_edge: any
  all_edge_data: any
  single_edge: any
  create_edge: any
  update_edge: any

  // selection group
  all_selection_group: any
  single_selection_group: any

  get_osg_products: any
  sorted_data: any
  product_heading: any
  upload_img: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: productsInterface = {
  //all_products
  all_products_data: [],

  //bread
  all_bread: [],
  all_bread_data: [],
  single_bread: {},
  create_bread: {},
  update_bread: {},

  //chicken
  all_chicken: [],
  all_chicken_data: [],
  single_chicken: {},
  create_chicken: {},
  update_chicken: {},

  // sandwich
  all_sandwich: [],
  all_sandwich_data: [],
  single_sandwich: {},
  create_sandwich: {},
  update_sandwich: {},

  // wrap
  all_wrap: [],
  all_wrap_data: [],
  single_wrap: {},
  create_wrap: {},
  update_wrap: {},

  // load_potato
  all_load_potato: [],
  all_load_potato_data: [],
  single_load_potato: {},
  create_load_potato: {},
  update_load_potato: {},

  // dessert
  all_dessert: [],
  all_dessert_data: [],
  single_dessert: {},
  create_dessert: {},
  update_dessert: {},

  // drink
  all_drink: [],
  all_drink_data: [],
  single_drink: {},
  create_drink: {},
  update_drink: {},

  // souce
  all_souce: [],
  all_souce_data: [],
  single_souce: {},
  create_souce: {},
  update_souce: {},

  // salad
  all_salad: [],
  all_salad_data: [],
  single_salad: {},
  create_salad: {},
  update_salad: {},

  // topping
  all_topping: [],
  all_topping_data: [],
  single_topping: {},
  create_topping: {},
  update_topping: {},

  // topping_price
  all_topping_price: [],
  all_topping_price_data: [],
  single_topping_price: {},
  create_topping_price: {},
  update_topping_price: {},

  // pizza
  all_pizza: [],
  all_pizza_data: [],
  single_pizza: {},
  create_pizza: {},
  update_pizza: {},

  // dough
  all_dough: [],
  all_dough_data: [],
  single_dough: {},
  create_dough: {},
  update_dough: {},
  all_edge: [],
  all_edge_data: [],
  single_edge: {},
  create_edge: {},
  update_edge: {},

  // selection group
  all_selection_group: [],
  single_selection_group: {},

  get_osg_products: {},
  sorted_data: [],
  product_heading: "",
  upload_img: "",
  activity: false,
}

export const get_all_products = createAsyncThunk(
  "all/products",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "products")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_all_bread = createAsyncThunk(
  "all/breads",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "breads")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_bread = createAsyncThunk(
  "single/sizes",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "breads")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_bread = createAsyncThunk(
  "product/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "product"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// get osg products //

export const get_osgg_products = createAsyncThunk(
  "/products/get-osg-products",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "osg-products"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_bread = createAsyncThunk(
  "bread/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "product"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// chicken
export const get_all_chickens = createAsyncThunk(
  "all/chicken",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "chicken")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_chicken = createAsyncThunk(
  "single/chicken",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "chicken")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_chicken = createAsyncThunk(
  "chicken/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "chicken"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_chicken = createAsyncThunk(
  "chicken/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "product"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// sandwich
export const get_all_sandwich = createAsyncThunk(
  "all/sandwich",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sandwich")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_sandwich = createAsyncThunk(
  "single/sandwich",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sandwich")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_sandwich = createAsyncThunk(
  "sandwich/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sandwich"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_sandwich = createAsyncThunk(
  "sandwich/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sandwich"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// wrap
export const get_all_wrap = createAsyncThunk(
  "all/wrap",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "wrap")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_wrap = createAsyncThunk(
  "single/wrap",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "wrap")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_wrap = createAsyncThunk(
  "wrap/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "wrap"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_wrap = createAsyncThunk(
  "wrap/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "wrap"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// load_potato
export const get_all_load_potato = createAsyncThunk(
  "all/load_potato",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "load_potato")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_load_potato = createAsyncThunk(
  "single/load_potato",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "load_potato")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_load_potato = createAsyncThunk(
  "load_potato/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "load_potato"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_load_potato = createAsyncThunk(
  "load_potato/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "load_potato"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// dessert
export const get_all_dessert = createAsyncThunk(
  "all/dessert",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dessert")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_dessert = createAsyncThunk(
  "single/dessert",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dessert")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_dessert = createAsyncThunk(
  "dessert/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dessert"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_dessert = createAsyncThunk(
  "dessert/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dessert"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// drink
export const get_all_drink = createAsyncThunk(
  "all/drink",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "drink")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_drink = createAsyncThunk(
  "single/drink",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "drink")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_drink = createAsyncThunk(
  "drink/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "drink"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_drink = createAsyncThunk(
  "drink/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "drink"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// souce
export const get_all_souce = createAsyncThunk(
  "all/souce",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "souce")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_souce = createAsyncThunk(
  "single/souce",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "souce")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_souce = createAsyncThunk(
  "souce/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "souce"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_souce = createAsyncThunk(
  "souce/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "souce"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// salad
export const get_all_salad = createAsyncThunk(
  "all/salad",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "salad")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_salad = createAsyncThunk(
  "single/salad",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "salad")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_salad = createAsyncThunk(
  "salad/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "salad"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_salad = createAsyncThunk(
  "salad/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "salad"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// topping
export const get_all_topping = createAsyncThunk(
  "all/topping",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "topping")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_topping = createAsyncThunk(
  "single/topping",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "topping")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_topping = createAsyncThunk(
  "topping/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "topping"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_topping = createAsyncThunk(
  "topping/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "topping"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// topping_price
export const get_all_topping_price = createAsyncThunk(
  "all/topping_price",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "topping_price"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// pizza
export const get_all_pizza = createAsyncThunk(
  "all/pizza",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "pizza")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_pizza = createAsyncThunk(
  "single/pizza",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "pizza")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_pizza = createAsyncThunk(
  "pizza/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "pizza"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_pizza = createAsyncThunk(
  "pizza/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "pizza"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// dough
export const get_all_dough = createAsyncThunk(
  "all/dough",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dough")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)
export const get_all_edge = createAsyncThunk(
  "all/edge",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dough")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_dough = createAsyncThunk(
  "single/dough",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dough")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_edge = createAsyncThunk(
  "single/edge",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "dough")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_dough = createAsyncThunk(
  "dough/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dough"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_edge = createAsyncThunk(
  "edge/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dough"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_dough = createAsyncThunk(
  "dough/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dough"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_edge = createAsyncThunk(
  "edge/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "dough"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// selection_group
export const get_all_selection_group = createAsyncThunk(
  "all/selection_group",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "selection_group"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_selection_group = createAsyncThunk(
  "single/selection_group",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "selection_group"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

// upload product images
export const upload_product_media = createAsyncThunk(
  "upload/image",
  async (data: any) => {
    try {
      let formData = new FormData()
      formData.append("file", data.body)
      let response = await apiServices.postFromImage(
        data.end_point,
        formData,
        "productimage",
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      if (response?.status !== 200) {
        toast.error("File is too long.")
      } else {
        return response
      }
    } catch (err) {
      console.log(err)
    }
  }
)

// sort products
export const sort_products = createAsyncThunk(
  "products_swap",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "product"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    handle_clear_form: (state: productsInterface, action: any) => {
      state.all_products_data = []

      state.all_bread = []
      state.single_bread = {}
      state.create_bread = {}
      state.update_bread = {}

      //chicken
      state.all_chicken = []
      state.single_chicken = {}
      state.create_chicken = {}
      state.update_chicken = {}

      //sandwich
      state.all_sandwich = []
      state.single_sandwich = {}
      state.create_sandwich = {}
      state.update_sandwich = {}

      //wrap
      state.all_wrap = []
      state.single_wrap = {}
      state.create_wrap = {}
      state.update_wrap = {}

      //load_potato
      state.all_load_potato = []
      state.single_load_potato = {}
      state.create_load_potato = {}
      state.update_load_potato = {}

      //dessert
      state.all_dessert = []
      state.single_dessert = {}
      state.create_dessert = {}
      state.update_dessert = {}

      //drink
      state.all_drink = []
      state.single_drink = {}
      state.create_drink = {}
      state.update_drink = {}

      //souce
      state.all_souce = []
      state.single_souce = {}
      state.create_souce = {}
      state.update_souce = {}

      //salad
      state.all_salad = []
      state.single_salad = {}
      state.create_salad = {}
      state.update_salad = {}

      //pizza
      state.all_pizza = []
      state.single_pizza = {}
      state.create_pizza = {}
      state.update_pizza = {}

      //topping
      state.all_topping = []
      // state.all_topping_data = [];
      state.single_topping = []
      state.create_topping = {}
      state.update_topping = {}

      //dough
      state.all_dough = []
      state.all_dough_data = []
      state.single_dough = []
      state.create_dough = {}
      state.update_dough = {}
      state.create_edge = {}
      state.update_edge = {}

      //selection_group
      state.all_selection_group = []
      state.single_selection_group = {}

      state.get_osg_products = {}

      state.upload_img = ""
      state.activity = false
    },

    handle_checked_pizzas: (state: any, action) => {
      state.single_pizza.products[action.payload.index].is_in_half_half =
        action.payload.value
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(get_all_products.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_products.fulfilled, (state: any, { payload }: any) => {
        state.all_products_data = payload?.data
        state.activity = false
      })

      .addCase(get_all_bread.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_bread.fulfilled, (state: any, { payload }: any) => {
        state.all_bread_data = payload?.data
        state.activity = false
      })

      // create bread
      .addCase(create_bread.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_bread.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_bread = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update bread
      .addCase(update_bread.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_bread.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_bread = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // single bread
      .addCase(get_single_bread.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_bread.fulfilled, (state: any, { payload }: any) => {
        state.single_bread = payload?.data
        state.activity = false
      })

      // get all chicken
      .addCase(get_all_chickens.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_chickens.fulfilled, (state: any, { payload }: any) => {
        state.all_chicken_data = payload?.data
        state.activity = false
      })

      // single chicken
      .addCase(get_single_chicken.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_chicken.fulfilled, (state: any, { payload }: any) => {
        state.single_chicken = payload?.data
        state.activity = false
      })

      // create chicken
      .addCase(create_chicken.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_chicken.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_chicken = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update chicken
      .addCase(update_chicken.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_chicken.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_chicken = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all sandwich
      .addCase(get_all_sandwich.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_sandwich.fulfilled, (state: any, { payload }: any) => {
        state.all_sandwich_data = payload?.data
        state.activity = false
      })

      // single sandwich
      .addCase(get_single_sandwich.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(
        get_single_sandwich.fulfilled,
        (state: any, { payload }: any) => {
          state.single_sandwich = payload?.data
          state.activity = false
        }
      )

      // create sandwich
      .addCase(create_sandwich.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_sandwich.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_sandwich = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update sandwich
      .addCase(update_sandwich.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_sandwich.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_sandwich = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all wrap
      .addCase(get_all_wrap.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_wrap.fulfilled, (state: any, { payload }: any) => {
        state.all_wrap_data = payload?.data
        state.activity = false
      })

      // single wrap
      .addCase(get_single_wrap.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_wrap.fulfilled, (state: any, { payload }: any) => {
        state.single_wrap = payload?.data
        state.activity = false
      })

      // create wrap
      .addCase(create_wrap.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_wrap.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_wrap = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update wrap
      .addCase(update_wrap.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_wrap.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_wrap = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all load_potato
      .addCase(get_all_load_potato.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(
        get_all_load_potato.fulfilled,
        (state: any, { payload }: any) => {
          state.all_load_potato_data = payload?.data
          state.activity = false
        }
      )

      // single load_potato
      .addCase(
        get_single_load_potato.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )
      .addCase(
        get_single_load_potato.fulfilled,
        (state: any, { payload }: any) => {
          state.single_load_potato = payload?.data
          state.activity = false
        }
      )

      // create load_potato
      .addCase(create_load_potato.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_load_potato.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_load_potato = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update load_potato
      .addCase(update_load_potato.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_load_potato.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_load_potato = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all dessert
      .addCase(get_all_dessert.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_dessert.fulfilled, (state: any, { payload }: any) => {
        state.all_dessert_data = payload?.data
        state.activity = false
      })

      // single dessert
      .addCase(get_single_dessert.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_dessert.fulfilled, (state: any, { payload }: any) => {
        state.single_dessert = payload?.data
        state.activity = false
      })

      // create dessert
      .addCase(create_dessert.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_dessert.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_dessert = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update dessert
      .addCase(update_dessert.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_dessert.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_dessert = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all drink
      .addCase(get_all_drink.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_drink.fulfilled, (state: any, { payload }: any) => {
        state.all_drink_data = payload?.data
        state.activity = false
      })

      // single drink
      .addCase(get_single_drink.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_drink.fulfilled, (state: any, { payload }: any) => {
        state.single_drink = payload?.data
        state.activity = false
      })

      // create drink
      .addCase(create_drink.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_drink.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_drink = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update drink
      .addCase(update_drink.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_drink.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_drink = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all souce
      .addCase(get_all_souce.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_souce.fulfilled, (state: any, { payload }: any) => {
        state.all_souce_data = payload?.data
        state.activity = false
      })

      // single souce
      .addCase(get_single_souce.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_souce.fulfilled, (state: any, { payload }: any) => {
        state.single_souce = payload?.data
        state.activity = false
      })

      // create souce
      .addCase(create_souce.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_souce.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_souce = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update souce
      .addCase(update_souce.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_souce.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_souce = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all topping
      .addCase(get_all_topping.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_topping.fulfilled, (state: any, { payload }: any) => {
        let topping = [] as any
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index]
          topping.push([
            item?.topping_code || "--",
            item?.topping_name?.en || "--",
            item?.en_content?.web || "--",
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#6EC531" : "#d2112b",
            },
            { name: item?.idx || "--" },
            {
              name: item?.created_at
                ? moment(item?.created_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            { name: item?.added_by || "Admin" },
            {
              name: item?.updated_at
                ? moment(item?.updated_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            item?.modified_by
              ? { name: item?.modified_by || "--" }
              : {
                  name: item?.modified_by || "--",
                  alignment: "center",
                },
          ])
        }
        state.all_topping = topping
        state.all_topping_data = payload?.data
        state.activity = false
      })

      // single topping
      .addCase(get_single_topping.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_topping.fulfilled, (state: any, { payload }: any) => {
        state.single_topping = payload?.data
        state.activity = false
      })

      // create topping
      .addCase(create_topping.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_topping.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_topping = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update topping
      .addCase(update_topping.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_topping.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_topping = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // get all topping_price
      .addCase(
        get_all_topping_price.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )
      .addCase(
        get_all_topping_price.fulfilled,
        (state: any, { payload }: any) => {
          let topping_price = [] as any
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data?.[index]
            topping_price.push([
              item?.topping_price_code || "--",
              item?.topping_price_name?.en || "--",
              item?.en_content?.web || "--",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "gray",
              },
            ])
          }
          state.all_topping_price = topping_price
          state.all_topping_price_data = payload?.data
          state.activity = false
        }
      )

      // get all dough
      .addCase(get_all_dough.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_dough.fulfilled, (state: any, { payload }: any) => {
        let dough = [] as any
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index]
          dough.push([
            item?.dough_code || "--",
            item?.dough_short_desc?.en || "--",
            item?.dough_desc?.en || "--",
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#6EC531" : "#d2112b",
            },
            { name: item?.idx || "--", alignment: "center" },
            {
              name: item?.created_at
                ? moment(item?.created_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            { name: item?.added_by || "Admin" },
            {
              name: item?.updated_at
                ? moment(item?.updated_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            item?.modified_by
              ? { name: item?.modified_by || "--" }
              : {
                  name: item?.modified_by || "--",
                  alignment: "center",
                },
          ])
        }
        state.all_dough = dough
        state.all_dough_data = payload?.data
        state.activity = false
      })
      // get all edge
      .addCase(get_all_edge.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_edge.fulfilled, (state: any, { payload }: any) => {
        let edge = [] as any
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index]
          edge.push([
            item?.dough_code || "--",
            item?.dough_short_desc?.en || "--",
            item?.dough_desc?.en || "--",
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#6EC531" : "#d2112b",
            },
            { name: item?.idx || "--", alignment: "center" },
            {
              name: item?.created_at
                ? moment(item?.created_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            { name: item?.added_by || "Admin" },
            {
              name: item?.updated_at
                ? moment(item?.updated_at).format(
                    `${dateFormat} ${timeFormatWithSec}`
                  )
                : "--",
            },
            item?.modified_by
              ? { name: item?.modified_by || "--" }
              : {
                  name: item?.modified_by || "--",
                  alignment: "center",
                },
          ])
        }
        state.all_edge = edge
        state.all_edge_data = payload?.data
        state.activity = false
      })
      // single dough
      .addCase(get_single_dough.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_dough.fulfilled, (state: any, { payload }: any) => {
        state.single_dough = payload?.data
        state.activity = false
      })

      // create dough
      .addCase(create_dough.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_dough.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_dough = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update dough
      .addCase(update_dough.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_dough.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_dough = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // create edge
      .addCase(create_edge.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_edge.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_edge = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
      // update edge
      .addCase(update_edge.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_edge.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_edge = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
      // single edge
      // single dough
      .addCase(get_single_edge.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_edge.fulfilled, (state: any, { payload }: any) => {
        state.single_edge = payload?.data
        state.activity = false
      })

      // get all selection_group
      .addCase(
        get_all_selection_group.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )
      .addCase(
        get_all_selection_group.fulfilled,
        (state: any, { payload }: any) => {
          let selection_group = [] as any
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data?.[index]
            selection_group.push([
              item?.selection_group_code || "--",
              item?.selection_group_name?.en || "--",
              item?.en_content?.web || "--",
              {
                value: item?.is_active,
                name: item?.is_active ? "Enabled" : "Disabled",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#d2112b",
              },
            ])
          }
          state.all_selection_group = selection_group
          state.all_selection_group = payload?.data
          state.activity = false
        }
      )

      // single selection_group
      .addCase(
        get_single_selection_group.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )
      .addCase(
        get_single_selection_group.fulfilled,
        (state: any, { payload }: any) => {
          state.single_selection_group = payload?.data
          state.activity = false
        }
      )

      // get all pizza
      .addCase(get_all_pizza.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_all_pizza.fulfilled, (state: any, { payload }: any) => {
        state.all_pizza_data = payload?.data
        state.activity = false
      })

      // single pizza
      .addCase(get_single_pizza.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(get_single_pizza.fulfilled, (state: any, { payload }: any) => {
        state.single_pizza = payload?.data
        state.activity = false
      })

      // create pizza
      .addCase(create_pizza.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_pizza.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_pizza = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // update pizza
      .addCase(update_pizza.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_pizza.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_pizza = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // sort products
      .addCase(sort_products.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(sort_products.fulfilled, (state: any, { payload }: any) => {
        try {
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      // product image
      .addCase(upload_product_media.pending, (state: any, { payload }: any) => {
        state.activity = true
      })
      .addCase(
        upload_product_media.fulfilled,
        (state: any, { payload }: any) => {
          state.upload_img = payload?.data
          state.activity = false
        }
      )

      .addCase(get_osgg_products.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(get_osgg_products.fulfilled, (state: any, { payload }: any) => {
        try {
          state.get_osg_products = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
  },
})

export const { handle_clear_form, handle_checked_pizzas } = productSlice.actions

export default productSlice.reducer
