import { Grid } from '@mui/material'
import useStyles from 'routes/style-jss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import moment from 'moment'
import React from 'react'
import { ArrowForwardIos } from '@mui/icons-material'

const Breadcrumbs = () => {
    const classes = useStyles() as any
    let BreadcrumbTitle = useSelector((state: RootState) => state.breadcrumb)
    const [time, setTime] = React.useState(new Date())

    React.useEffect(() => {
        let timer = setInterval(() => {
            setTime(new Date())
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <div className={classes.breadcrumb_container}>
            <Grid container spacing={2}>
                <Grid
                    xs={12}
                    md={7}
                    item
                    className={classNames(
                        classes.align_center,
                        classes.breadcrumb_head
                    )}
                >
                    {BreadcrumbTitle?.title || ''}
                    {BreadcrumbTitle?.sub_title && (
                        <>
                            <ArrowForwardIos
                                style={{
                                    margin: '0px 15px',
                                    fontSize: '1.5rem',
                                }}
                            />
                            <div
                                className={classNames(
                                    classes.breadcrumb_description
                                )}
                            >
                                <h6 style={{ fontSize: 24 }}>
                                    {BreadcrumbTitle?.sub_title || ''}
                                </h6>
                            </div>
                        </>
                    )}
                </Grid>

                <Grid xs={12} md={5} item className={'breadcrumb_time'}>
                    <div className={classes.breadcrumb_description}>
                        {`${moment().format('dddd[,] DD.MMMM.YYYY')} ${moment(
                            time
                        )
                            .utcOffset('+04:00')
                            .format('HH:mm')}`}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Breadcrumbs
