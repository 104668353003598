import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { all_modules } from 'store/modulesReducer'
import { capitalize_string } from 'utils/helper'
import NavCollapse from '../NavCollapse'
import NavItem from '../NavItem'
import { getDataFromLocalStorage } from 'utils/localStore.utils'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [state, setState] = React.useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            menuItems: {} as any,
        }
    )

    const modules_slicer = useSelector<any>(
        (state: RootState) => state.modules
    ) as any
    const dispatch = useDispatch<any>()

    const get_all_modules = () => {
        try {
            dispatch(
                all_modules({
                    end_point: '/modules',
                })
            )
        } catch (error) {
            console.log(error)
        }
    }

    const restructureArray = (
        inputArray: any,
        parentId: any = null,
        parentName: any = null
    ): any => {
        const result: any = []
        inputArray.forEach((item: any) => {
            if (item?.parent_module_id === parentId) {
                const newItem: any = {
                    module_id: item?._id,
                    id: `${item?.module_name?.toLowerCase().replace(/\s/g, '-')}`,
                    title: capitalize_string(item?.module_name),
                    visibility: true,
                }

                const children = restructureArray(
                    inputArray,
                    item?._id,
                    parentName
                        ? `${parentName}/${item?.module_name
                              ?.toLowerCase()
                              .replace(/ /g, '-')}`
                        : `/${item?.module_name?.toLowerCase().replace(/ /g, '-')}`
                )

                if (children?.length > 0) {
                    // If there are multiple children or the parent is a 'collapse', add as 'collapse'
                    newItem.type = 'collapse'
                    newItem.children = children
                } else {
                    // If there are no children, treat as an 'item'
                    newItem.type = 'item'
                    newItem.url = parentName
                        ? `${parentName}/${item?.module_name
                              ?.toLowerCase()
                              .replace(/ /g, '-')}`
                        : `/${item?.module_name?.toLowerCase().replace(/ /g, '-')}`
                    newItem.breadcrumbs = true
                }
                result.push(newItem)
            }
        })
        return result
    }

    // eslint-disable-next-line
    const can_perform_action = (item: any) => {
        try {
            let user = getDataFromLocalStorage('user')

            let indexz = user?.permissions?.findIndex((itemx: any) => {
                return Number(itemx?.module_id) === Number(item?.module_id)
            })
            if (
                (item?.type === 'item' && user?.permissions?.[indexz]?.read) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.write) ||
                (item?.type === 'item' &&
                    user?.permissions?.[indexz]?.update) ||
                (item?.type === 'item' &&
                    user?.permissions?.[indexz]?.delete) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.print) ||
                (item?.type === 'item' && user?.permissions?.[indexz]?.download)
            ) {
                return true
            } else if (
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.read) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.write) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.update) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.delete) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.print) ||
                (item?.type === 'collapse' &&
                    user?.permissions?.[indexz]?.download)
            ) {
                return true
            }
        } catch (error) {
            console.log(error)
        }
    }

    //eslint-disable-next-line
    const navItems = state?.modules_list?.map((item: any) => {
        if (can_perform_action(item)) {
            switch (item.type) {
                case 'item':
                    return <NavItem key={item?.id} item={item} level={1} />
                case 'collapse':
                    return <NavCollapse key={item?.id} menu={item} level={1} />

                default:
                    return (
                        <Typography
                            key={item?.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Menu Items Error
                        </Typography>
                    )
            }
        }
    })

    useEffect(() => {
        get_all_modules()

        //eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        const modules = restructureArray(
            modules_slicer?.all_modules_data ?? []
        ) as any

        setState({
            modules_list: modules,
        })
        // eslint-disable-next-line
    }, [JSON.stringify(modules_slicer?.all_modules)])

    return <>{navItems}</>
}

export default MenuList
